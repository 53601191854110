import { FollowType } from "@product/scmp-sdk";

import type { FollowListKeys } from "./types";

export const FollowTypeKeyMapping: Record<string, FollowListKeys> = {
  [FollowType.Author]: "authors",
  [FollowType.Section]: "sections",
  [FollowType.Topic]: "topics",
};
export const UserFollowSwrFetchKey = "/user-follow/user-follow-list";
