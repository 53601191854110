import type { FollowType } from "@product/scmp-sdk";
import { FollowButtonAction } from "@product/scmp-sdk";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";
import useSWRImmutable from "swr/immutable";

import { useSnackbarSimple } from "scmp-app/components/common/snackbar/hooks";
import { config } from "scmp-app/data";
import { accountAtom } from "scmp-app/lib/account";
import { useDeferredAction } from "scmp-app/lib/hooks";
import { parseArray } from "scmp-app/lib/utils";

import { create, fetchList } from "./apis";
import { userFollowAtom } from "./atoms";
import { UserFollowSwrFetchKey } from "./consts";
import { SnackbarMessageContainer, SnackbarViewAllLink } from "./styles";
import { generateNewList } from "./utils";

export const useUserFollowInit = () => {
  const { isLoggedIn } = useAtomValue(accountAtom);
  const setUserFollowState = useSetAtom(userFollowAtom);

  const { data, mutate } = useSWRImmutable(isLoggedIn ? UserFollowSwrFetchKey : null, fetchList, {
    refreshInterval: 1000 * 30,
    revalidateOnMount: true,
  });

  useEffect(() => {
    if (!data) return;
    setUserFollowState(current => ({ ...current, data }));
  }, [data, setUserFollowState]);

  useEffect(() => {
    if (!mutate) return;
    setUserFollowState(current => ({ ...current, mutate }));
  }, [mutate, setUserFollowState]);
};

export const useUserFollow = () => {
  const { isLoggedIn, user } = useAtomValue(accountAtom);
  const { data, mutate } = useAtomValue(userFollowAtom);
  const { deferRun } = useDeferredAction([!!data, !!mutate]);
  const { handleOpenSnackbar } = useSnackbarSimple();

  const checkIsFollowed = useCallback(
    (type: FollowType, entityId: string) => !!data?.[`${type}s`].includes(entityId),
    [data],
  );

  const handleFollowAction = useCallback(
    (
      entityIds: string | string[],
      type: FollowType,
      action: FollowButtonAction,
      callback?: () => void,
    ) => {
      if (!isLoggedIn) return;
      deferRun(async () => {
        const payload = {
          action,
          ids: parseArray(entityIds),
          type,
          uuid: user?.uuid ?? "",
        };
        await mutate?.(data => create(data, payload), {
          optimisticData: data => generateNewList(data, payload),
          populateCache: true,
          revalidate: false,
          rollbackOnError: true,
        });
        const message = action === FollowButtonAction.Follow ? "Following" : "Unfollowed";
        const snackbarParameter = {
          duration: 4000,
          leftElement: <SnackbarMessageContainer>{message}</SnackbarMessageContainer>,
          rightElement: (
            <SnackbarViewAllLink pathname={config.account.scmpAccountHost + "/manage/following"}>
              View all
            </SnackbarViewAllLink>
          ),
        };

        handleOpenSnackbar(snackbarParameter);
        callback?.();
      });
    },
    [deferRun, handleOpenSnackbar, isLoggedIn, mutate, user?.uuid],
  );

  return {
    checkIsFollowed,
    handleFollowAction,
  };
};
