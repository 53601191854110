import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto, transientOptions } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { Button } from "scmp-app/components/common/button";

const buttonCss = css`
  inline-size: 100%;
  block-size: 32px;
  padding-block: 8px;
  padding-inline: 0;

  font-weight: unset;
  font-size: 14px;
  font-family: ${fontRoboto};
  text-align: center;

  border-radius: 3px;

  background-color: unset;
`;

export type FollowButtonTheme = "blue" | "gray" | "white";

type FollowingButtonTheme = "blue" | "gray";

export type FollowButtonProps = {
  $theme?: FollowButtonTheme;
};

export const FollowButton = styled(Button, { ...transientOptions })<FollowButtonProps>`
  ${buttonCss}
  ${props => {
    const map = {
      blue: css`
        color: #4585ff;

        border: 1px solid #4585ff;
      `,
      gray: css`
        color: #ffffff;

        border: 1px solid #ffffff;
      `,
      white: css`
        color: #ffffff;

        border: 1px solid #ffffff;
      `,
    };
    return props.$theme ? map[props.$theme] : css``;
  }}
`;

export type FollowingButtonProps = {
  $followingButtonTheme?: FollowingButtonTheme;
} & FollowButtonProps;

export const FollowingButton = styled(Button, { ...transientOptions })<FollowingButtonProps>`
  ${buttonCss}
  ${({ $followingButtonTheme = "gray", $theme }) => {
    const followingButtonMap = {
      blue: css`
        color: #ffffff;

        border: 1px solid #4585ff;

        background: #4585ff;
      `,
      gray: css`
        color: #001246;

        border: 1px solid #e5e5e5;

        background: #e5e5e5;
      `,
    };
    const map = {
      blue: $followingButtonTheme ? followingButtonMap[$followingButtonTheme] : css``,
      gray: css`
        color: #001246;

        border: 1px solid #4585ff;

        background: #4585ff;
      `,
      white: css`
        color: #001246;

        border: 1px solid #ffffff;

        background: #ffffff;
      `,
    };
    return $theme ? map[$theme] : css``;
  }}
`;

export const SnackbarMessageContainer = styled.span`
  color: #ffffff;
  font-size: 16px;
  font-family: ${fontRoboto};
`;

export const SnackbarViewAllLink = styled(BaseLink)`
  color: #4585ff;
  font-weight: 900;
  font-size: 16px;
  font-family: ${fontRoboto};
`;

export const ChildrenContainer = styled.div`
  position: relative;

  display: inline-flex;
  align-items: center;

  inline-size: fit-content;
`;
